<div class='scp-no-results'>
  <img alt=''
       src='/assets/images/svg/{{icon}}.svg'
       loading='lazy'
       [width]='width'
       [height]='height'
       [class.invert]='invert'>
  @if (title) {
    <h2 [innerHTML]='title | translate' class='scp-no-results__title'></h2>
  }
  <div [innerHTML]='text | translate'></div>
  @if (url) {
    <scp-btn [url]='url' type='1' [text]='urlText' [height]='54'></scp-btn>
  }
</div>
